import { useEffect, useState } from "react";
import Header from "../components/Header";
import LeadTabAbout from "../components/LeadTabAbout";
import LeadTabInsights from "../components/LeadTabInsights";
import LeadTabNotes from "../components/LeadTabNotes";
import LeadTabSettings from "../components/LeadTabSettings";
import { get, post, postJson } from "../helpers/network";
import { classNames } from "../helpers/utilities";
import { Tooltip } from "react-tooltip";
import LeadLogs from "../components/LeadLogs";
import {ReactComponent as FollowUps} from '../images/follow-up.svg'
import FollowUpModel from "../components/FollowUpModel";
export default function LeadDetails({
  onDelete,
  onSendMessage,
  onScheduleCall,
  onCallback,
  lead,
  onClosed,
  updateLead,
  refresh,
  assignList,
}) {
  const [activeTab, setActiveTab] = useState("about");

  const [leadOwner, setLeadOwner] = useState(lead.owner_id);
  const [leadStatus, setLeadStatus] = useState(lead.status);
  const [leadInterest, setLeadInterest] = useState(lead.interseted);
  const [goodFit, setGoodFit] = useState(lead.qualification);
  const [openFollowUp, setOpenFollowUp] = useState(false);

  const [leadInfo, setLeadInfo] = useState({});

  const getLeadInfo = async () => {
    var leadData = await get(`leads/lead/${lead?.id}`);
    var result = await leadData.json();
    setLeadInfo(result || {});
  };

  useEffect(() => {
    getLeadInfo();
  }, []);

  useEffect(() => {
    const saveOwner = async () => {
      if (leadOwner != lead.owner_id) {
        var ownerData = new FormData();
        ownerData.append("lead_id", lead.id);
        ownerData.append("user_id", leadOwner);
        updateLead(lead.id, "owner_id", leadOwner);
        await post(`leads/set-owner`, ownerData);
        refresh();
      }
    };

    saveOwner();
  }, [leadOwner]);



  useEffect(() => {
    const saveStatus = async () => {
      if (leadStatus != lead.status) {
        var statusData = JSON.stringify({
          lead_id: lead.id,
          status: "" + leadStatus,
        });

        updateLead(lead.id, "status", leadStatus);
        await postJson("leads/change-status", statusData);
      }
    };

    saveStatus();
  }, [leadStatus]);

  useEffect(() => {
    var interestData = JSON.stringify({
      lead_id: lead.id,
      interested: goodFit,
    });

    updateLead(lead.id, "qualification", goodFit);

    postJson("leads/change-interested", interestData);
  }, [goodFit]);

  const saveInterest = async (interest) => {
    var infoData = { lead_interest: interest };
    await postJson(`leads/${lead.id}/info`, JSON.stringify(infoData));
  };
  const saveScore = async (score) => {
    var infoData = { score: score };
    await postJson(`leads/${lead.id}/scores`, JSON.stringify(infoData));
    refresh();
  };

  const saveTags = async (tags) => {
    var infoData = { lead_id: lead.id, tags: [tags] };
    await postJson(`leads/add-tags`, JSON.stringify(infoData));
    refresh();
  };

  const saveStages = async (tags) => {
    var infoData = { lead_id: lead.id, stage: tags };
    await postJson(`user/change-lead-stage`, JSON.stringify(infoData));
    refresh();
  };

  const closeModal = () => {
    onClosed();
  };

const handleFollowUp = () => { setOpenFollowUp(!openFollowUp) }

  return (
    <>
      <section className="call-summary pb-24">
        <div className="m-s-top">
          <h1>
            Lead Details (#<span id="selected_lead_id">{lead.id}</span>)
          </h1>
          <div
            id="lead_id"
            className="text-white text-center flex justify-center gap-3 mr-auto ml-2"
          >
            <a
              onClick={onCallback}
              data-tooltip-content="Callback"
              href="#"
              id="ld_callback_btn"
              title="Callback"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                />
              </svg>
            </a>

            <a
              onClick={onScheduleCall}
              data-tooltip-content="Schedule"
              href="#"
              id="ld_schedule_btn"
              title="Schedule"
            >
              <svg
                title="Schedule"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
            </a>

            <a
              onClick={onSendMessage}
              href="#"
              data-tooltip-content="Send a message"
              id="ld_message_btn"
              title="Message"
            >
              <svg
                title="Message"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                />
              </svg>
            </a>
            <a
              onClick={handleFollowUp}
              href="#"
              data-tooltip-content="Send a message"
              id="ld_message_btn"
              title="Message"
            >
            <FollowUps  height={24} width={24} fill="#fff"/>
            </a>
          </div>

          <a
            onClick={closeModal}
            id="goto-leads"
            className="close-settings"
            href="#"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.70703 0.292969L0.292969 1.70703L6.58594 8L0.292969 14.293L1.70703 15.707L8 9.41406L14.293 15.707L15.707 14.293L9.41406 8L15.707 1.70703L14.293 0.292969L8 6.58594L1.70703 0.292969Z"
                fill="#A4ABC4"
              />
            </svg>
          </a>
        </div>
        <span title="Delete Lead">
          <svg
            onClick={onDelete}
            data-tooltip-content="Delete"
            id="lead-delete"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="text-red-500 w-6 h-6 absolute right-8 -mt-2 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </span>

        <div>
          <div
            id="lead_name"
            className="number text-white text-center font-bold"
          >
            {lead.customer_name ? lead.customer_name : "Unknown"}
          </div>
          <div id="lead_company" className="number text-white text-center">
            {lead.company_name ||  lead?.phone_number}
          </div>
         
         
        </div>

        <div className="flex justify-center	 text-gray-500 gap-4 my-2">
          <div>Good Fit?</div>
          <span title="Mark as qualified">
            <svg
              onClick={() => {
                setGoodFit(1);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={classNames(
                goodFit == 1 ? "text-green-500" : "",
                "cursor-pointer w-6 h-6 good_fit_selector"
              )}
              data-goodfit="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
              />
            </svg>
          </span>
          <span title="Mark as not qualified">
            <svg
              onClick={() => {
                setGoodFit(3);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={classNames(
                goodFit == 3 ? "text-red-500" : "",
                "cursor-pointer w-6 h-6 good_fit_selector"
              )}
              data-goodfit="3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384"
              />
            </svg>
          </span>
        </div>

        <div className="home-numbers lead-numbers">
          <div id="goto-leads" className="item pointer">
            <div className="number" id="visit">
              0
            </div>
            <h3>Visit</h3>
          </div>
          <div id="goto-leads" className="item pointer">
            <div className="number" id="chats">
              0
            </div>
            <h3>Leads</h3>
          </div>
          <div id="goto-meetings" className="item pointer">
            <div className="number" id="callbacks">
              0
            </div>
            <h3>Callbacks</h3>
          </div>
          <div id="goto-leads" className="item pointer">
            <div className="number" id="missed">
              0
            </div>
            <h3>Missed Calls</h3>
          </div>
        </div>

        <div id="lead-main-configurations">
          <div className="item">
            <label htmlFor="">Owner</label>
            <select
              onChange={(e) => {
                setLeadOwner(e.target.value);
              }}
              value={leadOwner ? leadOwner : ""}
              id="lead_owner"
            >
              <option key="demo" value="">
                Unassigned
              </option>

              {assignList &&
                assignList.map((teamMember) => (
                  <option key={teamMember.value} value={teamMember.value}>
                    {teamMember?.text}
                  </option>
                ))}
            </select>
          </div>
          <div className="item">
            <label htmlFor="">Lead status</label>
            <select
              value={leadStatus ? leadStatus : ""}
              onChange={(e) => {
                setLeadStatus(e.target.value);
              }}
              id="lead_status"
            >
              <option value="1">Pending</option>
              <option value="2">Assigned</option>
              <option value="3">Closed</option>
              <option value="4">Qualified</option>
            </select>
          </div>
          <div className="item">
            <label htmlFor="">Interested in</label>
            <select
              value={leadInterest}
              onChange={(e) => {
                setLeadInterest(e.target.value);
                saveInterest(e.target.value);
              }}
              id="lead_interest"
            >
              <option>Sales</option>
              <option>Troubleshooting</option>
              <option>Customer Support</option>
              <option>Follow up</option>
            </select>
          </div>
        </div>
       
        <div className="leads-tabs lead-details-tabs">
          <ul className="nav nav-tabs !top-8" id="myTab" role="tablist">
            <li
              onClick={() => {
                setActiveTab("about");
              }}
              className={classNames(
                activeTab == "about" ? "active" : "",
                "nav-item"
              )}
            >
              <span
                className={classNames(
                  activeTab == "about" ? "active" : "",
                  "nav-link"
                )}
                id="pendingleads"
                data-toggle="tab"
                href=""
                role="tab"
                aria-controls="about"
                aria-selected="true"
              >
                About
              </span>
            </li>
            <li
              onClick={() => {
                setActiveTab("notes");
              }}
              className={classNames(
                activeTab == "notes" ? "active" : "",
                "nav-item"
              )}
            >
              <span
                className={classNames(
                  activeTab == "notes" ? "active" : "",
                  "nav-link"
                )}
                id="inprogessleads"
                data-toggle="tab"
                href=""
                role="tab"
                aria-controls="notes"
                aria-selected="false"
              >
                Notes
              </span>
            </li>
            <li
              onClick={() => {
                setActiveTab("insights");
              }}
              className={classNames(
                activeTab == "insights" ? "active" : "",
                "nav-item"
              )}
            >
              <span
                className={classNames(
                  activeTab == "insights" ? "active" : "",
                  "nav-link"
                )}
                id="myleads"
                data-toggle="tab"
                href=""
                role="tab"
                aria-controls="insights"
                aria-selected="false"
              >
                Insights
              </span>
            </li>
            <li
              onClick={() => {
                setActiveTab("settings");
              }}
              className={classNames(
                activeTab == "settings" ? "active" : "",
                "nav-item"
              )}
            >
              <span
                className={classNames(
                  activeTab == "settings" ? "active" : "",
                  "nav-link"
                )}
                id="allleads"
                data-toggle="tab"
                href=""
                role="tab"
                aria-controls="settings"
                aria-selected="false"
              >
                Settings
              </span>
            </li>
            <li
              onClick={() => {
                setActiveTab("logs");
              }}
              className={classNames(
                activeTab == "logs" ? "active" : "",
                "nav-item"
              )}
            >
              <span
                className={classNames(
                  activeTab == "logs" ? "active" : "",
                  "nav-link"
                )}
                id="allleads"
                data-toggle="tab"
                href=""
                role="tab"
                aria-controls="logs"
                aria-selected="false"
              >
                Logs
              </span>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className={classNames(
                activeTab == "about" ? "active" : "",
                "tab-pane"
              )}
              id="about"
              role="tabpanel"
              aria-labelledby="about-tab"
            >
              <LeadTabAbout lead={lead} leadInfo={leadInfo} />
            </div>

            <div
              className={classNames(
                activeTab == "notes" ? "active" : "",
                "tab-pane"
              )}
              id="notes"
              role="tabpanel"
              aria-labelledby="notes-tab"
            >
              <LeadTabNotes lead={lead} />
            </div>

            <div
              className={classNames(
                activeTab == "insights" ? "active" : "",
                "tab-pane"
              )}
              id="insights"
              role="tabpanel"
              aria-labelledby="insights-tab"
            >
              <LeadTabInsights lead={lead} />
            </div>

            <div
              className={classNames(
                activeTab == "settings" ? "active" : "",
                "tab-pane"
              )}
              id="settings"
              role="tabpanel"
              aria-labelledby="settings-tab"
            >
     

              <LeadTabSettings lead={lead} />
            </div>

            <div
              className={classNames(
                activeTab == "logs" ? "active" : "",
                "tab-pane"
              )}
              id="logs"
              role="tabpanel"
              aria-labelledby="logs-tab"
            >
              <LeadLogs lead={lead} leadInfo={leadInfo} />
            </div>
          </div>

          <div className="px-4">
            <button
              onClick={() => {
                window.open(`${window.endPoint}/leads/${lead.id}`);
              }}
              id="lead-view-on-web"
              className="mt-4 mb-24 bg-white text-gray-700 btn-main hover:!bg-[#12c197] !text-gray-500 hover:!text-white hover:!border-[#12c197]"
            >
              View on web
            </button>
          </div>
        </div>
      </section>
      <Tooltip anchorId="lead-delete" />
      <Tooltip anchorId="ld_callback_btn" />
      <Tooltip anchorId="ld_schedule_btn" />
      <Tooltip anchorId="ld_message_btn" />
      {openFollowUp &&
      <FollowUpModel id={lead.id}/>
      }
    </>
  );
}
