import axios from 'axios'
import React, { useState } from 'react'
import {
    errorAlert,
    postWithoutV1,

} from "../helpers/network";
const ResetPasswordModel = ({ onClosed }) => {
    
    const [email, setEmail] = useState('')
    
    const handleRequest = async(e) => {
        e.preventDefault();
        var formdata = new FormData();
    
        formdata.append("email", email);

        console.log('formData', formdata)
       const result= await postWithoutV1(`send-forget-password-token`, formdata)
        const resultJson = await result.json();
        if (resultJson?.message?.includes('Successfully')){
            onClosed()
        }else{
            errorAlert(resultJson?.errors?.[0]);

        }

    }

  return (
    <div>
      <section className='login h-full flex flex-col w-100' style={{backgroundColor:'transparent'}}>
              <h1 className='mt-4'>Request Reset Password Link</h1>
              <form onSubmit={handleRequest} id="login_form">

          <label>
              E-mail addresss:
              <input
                  value={email}
                  onChange={(e) => {
                      setEmail(e.target.value);
                  }}
                  id="login_email"
                  type="email"
              />
          </label>
                  <button disabled={!email}  type="submit" className="btn-main">
                     Request Link
                  </button>
        </form>
    </section>
      </div>
  )
}

export default ResetPasswordModel