import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getConversations } from "../helpers/messages";
import { get } from "../helpers/network";
import { classNames } from "../helpers/utilities";
import { PhoneIcon } from "@heroicons/react/24/outline";

const pathList = ["settings"];

const filters = {
  pending:
    "filter[type][!in]=SCHEDULE_CALL,TEXT_MESSAGE&filter[direction][is]=Inbound&filter[owner_id][is]=null&filter[status][!is]=3",
};
let interval = "";

export default function Footer() {
  const [activeTab, setActiveTab] = useState("messages");
  const [pendingCount, setPendingCount] = useState(0);
  const [conversations, setConversations] = useState(0);

  useEffect(() => {
    getPendingCount();
    fetchMessges();
  }, []);

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    makeInterval();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const makeInterval = () => {
    interval = setInterval(() => {
      getPendingCount();
      fetchMessges();
    }, 10000);
  };

  async function fetchMessges() {
    var messageState = 1;
    var coversationJson = await getConversations(messageState);
    if (
      coversationJson?.conversations &&
      coversationJson?.conversations?.length
    ) {
      var unreadCount = 0;
      coversationJson?.conversations.map((list) => {
        unreadCount = unreadCount + +list?.unread_messages;
      });
      setConversations(unreadCount);
    } else {
      setConversations(0);
    }
  }

  async function getPendingCount() {
    var pendingLeadsCount = await get(
      "leads?limit=10&page=1&sort_field=id&sort_value=DESC&sort=-id&" +
      filters["pending"]
    );
    pendingLeadsCount = await pendingLeadsCount.json();
    setPendingCount(pendingLeadsCount.data.leads.total || 0);
  }

  useEffect(() => {
    const path = window.location?.pathname?.replace("/", "");
    if (path && activeTab !== path) {
      setActiveTab(path);
    } else {
      setActiveTab("messages");
    }
  }, []);

  useEffect(() => {
    const path = window.location?.pathname?.replace("/", "");
    if (!path && activeTab !== "messages") {
      setActiveTab("messages");
    }
    if (pathList.includes(path)) {
      setActiveTab("");
    }
  }, [window.location?.pathname]);

  return (
    <div className="bottom-bar justify-center fixed-bar">
      <div className="bar flex">
        <Link
          onClick={() => {
            setActiveTab("messages");
          }}
          to="/"
          id="goto-messages"
          className={classNames(
            "item",
            activeTab == "messages" ? "current" : ""
          )}
        >
          <>
            <span
              className={`read-count ${+conversations !== 0 ? "" : "null-count"
                }`}
            >
              {conversations || 0}
            </span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </>
          <span>Messages</span>
        </Link>
       
        <Link
          onClick={() => {
            setActiveTab("logs");
          }}
          to="/logs"
          id="goto-home"
          className={classNames("item", activeTab == "logs" ? "current" : "")}
        >
          <img
            style={{ height: "35px" }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADqElEQVR4nO1aS2gUQRBdTfyh+EGFgHpRFEXxe1NQEEEXFPGwgl5E073B6M6YTY+JephDT1T8HPSkB0GJMRrRYBBFokz3Luohit/ujR/wgyAoBFEIHpSV2mw0zo63ZWcG60Edpl5T3f22qnpm6VgMgUAgEIiywU6lxjsGu3IilRpVwtXb47hpXT3e0DDGy4EPOBjj5SAWxITYsbCjZXd6rmNaebue1Xg5nmKzgOPp9IwSLp2eUeBSbJaXg1jAQexY2NHyPwnADdbMTXb6b7MuFTZiWq0+XHuRa/Ph2opcuw/XWuQulXAGaw5QAKufGyzjmFbHELtZWKxhdXn8HdywbhS56z7c9SJ3w4eDWPli7CF+loE1BCqAYzbGgyoBmBsFMEKWAScGjizekUhUecfbtj0SONu2q3246iI30stBLOC8R2soM6CSQAFMzIA4loCBPaA/FhQ4NkGr5BQ43NQ0gZvsHTdZX5ntHcSORBPkKbbOMa1EOQ1ieucJrQCVAgpgYgbEsQSMkPUA27aruWHt5wY7XF6z9ns/okJZAkcbG8c6But0DKu7vMY6IXboBagkUAATMyCOJWCEsAe0pPZNPbSneWY5DWJG6GPI+lH8G7tsBjEj8zFk17OacmeA39/soRWgUkABTMyAOJaAEWAPcPbs/d7VduXgp4/fEkEYzA1rCEyAgw1NP3uyPd8+ffzaF4TB3LCGwATgeApYeAw6/3gPSD54MGJn9ukkr59k9Jw6oWYP9W1z34yuyz5btD3z8s/rbj4/jGTVfOI+mR7JY5Bk9Hoq1YWhPir0SSrUEyr1IyLUWfDV3X4xjUiVI1JfI1K/JkJvKmxeqFtEqLtEqIdUaB45AWpFbgMRqn3weYerFxChVaIjX2Xn88NBhKTbO5cKfYRIZcEY+MWpVOe2uY8mEqm+NNx7PwYyiQp9J/ICEKE3UaFbfz9LfYYItZlKdZW6ak2teDYvKVQiKdVK4KnULVTo90SqY5AlkReAumoNbPb3s9AXiVQboUxACCJza4nQp6BEoHcQ9/niXd16MhXqAJRIVAW4RbK5ZWBJt3cKlfptYaPy+Woi1QfYIHH1FirVfWiCSZHbSoR+PFAK+lVS6qUD49VnKJ1oCZDpXUik7h40eN4h1BIq9GUiVVdtpnfF4Nikq3YSoW9Toc9TmVsOvkJWCN0JzZFm9Cq/OUItQCWAApiYAfHAS4CXXpWtmIXgqizzuSxdYQvysjQCgUAgEAhELML4BSHbAZ2fqntoAAAAAElFTkSuQmCC"
          />
          <span>Logs</span>
        </Link>

        <Link id="goto-home" to="/dialer" onClick={() => {
          setActiveTab("dialer");
        }}
          className={classNames("item", activeTab == "dialer" ? "current" : "")}
        >
          <PhoneIcon

            width="20"
            height="20"
            viewBox="0 0 18 20"
            className="icon-overflow"
            color="#a4abc4"

          />
          <span>Dialer</span>
        </Link>

        <Link
          onClick={() => {
            setActiveTab("leads");
          }}
          to="/leads"
          id="goto-leads"
          className={classNames("item", activeTab == "leads" ? "current" : "")}
        >
          <>
            <span
              className={`read-count ${+pendingCount !== 0 ? "" : "null-count"
                }`}
            >
              {pendingCount || 0}
            </span>
          </>
          <svg
            width="21"
            height="28"
            viewBox="0 0 21 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7733 0.665382C14.3421 0.670376 13.9083 0.729964 13.483 0.846372C9.70587 1.88004 6.36405 5.04575 3.42958 10.0287C0.491003 15.0184 -0.638943 19.4507 0.348851 23.1966C0.79247 24.8804 2.1056 26.2152 3.78765 26.7123L5.36578 27.1797C7.12963 27.7019 9.0378 26.8583 9.80328 25.1875L11.0246 22.5261C11.6785 21.1007 11.3274 19.4061 10.1692 18.3464L8.14573 16.4974C8.08377 16.4407 8.04875 16.3689 8.04026 16.2969V16.2956C8.02343 16.1473 7.99576 16.0948 8.11057 15.655C8.22561 15.2143 8.51131 14.4682 9.18349 13.3268C9.80938 12.2636 10.3942 11.5202 10.8176 11.1524C11.0551 10.9471 11.3244 10.7926 11.44 10.8255H11.4413L14.1561 11.6393C15.6635 12.0922 17.3007 11.5535 18.2304 10.2917L18.2317 10.2904L19.9647 7.93491H19.966C21.0517 6.45915 20.8499 4.38285 19.5064 3.13543L18.3072 2.02085C17.663 1.42299 16.8816 1.01367 16.0494 0.811215C15.6333 0.709986 15.2046 0.660388 14.7733 0.665382ZM14.7955 2.66668C15.0586 2.66453 15.3208 2.69589 15.5754 2.75783C16.0847 2.8817 16.562 3.13019 16.9465 3.487L18.1457 4.60028C18.7595 5.17019 18.8456 6.08184 18.3541 6.75002L16.621 9.10548C16.196 9.68233 15.439 9.93647 14.7317 9.72398L12.0051 8.90757L12.0025 8.90627C10.6967 8.52532 9.73603 9.44428 9.50771 9.64194H9.5064V9.64325C8.80176 10.2554 8.1483 11.1424 7.45953 12.3125C6.72104 13.5665 6.35233 14.4681 6.17437 15.1498C5.99641 15.8314 6.03676 16.3758 6.05328 16.5222C6.11702 17.0805 6.38307 17.595 6.79547 17.9727L8.8189 19.8229C9.35265 20.3113 9.50235 21.0475 9.20692 21.6914H9.20562L7.98557 24.3542C7.64171 25.1047 6.75497 25.5062 5.93349 25.263L4.35536 24.7956V24.7943C3.34098 24.495 2.54267 23.6752 2.28244 22.6875C1.49291 19.6934 2.36545 15.7785 5.15354 11.0443C7.93774 6.31654 10.958 3.61042 14.0116 2.77476C14.2683 2.7045 14.5324 2.66884 14.7955 2.66668Z"
              fill="#A4ABC4"
            />
          </svg>
          <span>Leads</span>
        </Link>
        <Link
          onClick={() => {
            setActiveTab("analytics");
          }}
          to="/analytics"
          id="goto-home"
          className={classNames("item", activeTab == "analytics" ? "current" : "")}
        >
          <svg
            width="25"
            height="27"
            viewBox="0 0 25 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3007 0.6665C12.0875 0.673781 11.8822 0.749062 11.7148 0.881343L2.2395 8.34619C1.0369 9.29394 0.333252 10.7422 0.333252 12.2733V24.9998C0.333252 25.9089 1.09086 26.6665 1.99992 26.6665H8.66659C9.57565 26.6665 10.3333 25.9089 10.3333 24.9998V18.3332C10.3333 18.1365 10.4699 17.9998 10.6666 17.9998H13.9999C14.1966 17.9998 14.3333 18.1365 14.3333 18.3332V24.9998C14.3333 25.9089 15.0909 26.6665 15.9999 26.6665H22.6666C23.5756 26.6665 24.3333 25.9089 24.3333 24.9998V12.2733C24.3333 10.7422 23.6296 9.29394 22.427 8.34619L12.9517 0.881343C12.7668 0.73526 12.5362 0.659172 12.3007 0.6665ZM12.3333 2.93994L21.19 9.9178C21.9128 10.4874 22.3333 11.3537 22.3333 12.2733V24.6665H16.3333V18.3332C16.3333 17.0565 15.2766 15.9998 13.9999 15.9998H10.6666C9.38995 15.9998 8.33325 17.0565 8.33325 18.3332V24.6665H2.33325V12.2733C2.33325 11.3537 2.75375 10.4874 3.47648 9.9178L12.3333 2.93994Z"
              fill="#A4ABC4"
            />
          </svg>
          <span>Analytics</span>
        </Link>
      </div>
    </div>
  );
}
