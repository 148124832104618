import axios from 'axios';
import React, { useEffect } from 'react'

const FollowUpModel = ({id}) => {

    useEffect(() => {
        axios
        .get(
          `${window.endPoint}/api/v1/user/contact/144/followup-messages`,
        
        )
        .then((res) => {


        })
        .catch(async (error) => {

        });
    

    }, [])
    
  return (
    <div>


    </div>
  )
}

export default FollowUpModel