import { useState } from "react";
import { post, postJson } from "../helpers/network";
import { useEffect } from "react";

export default function LeadTabSettings({ lead}) {

    const [leadScore, setLeadScore] = useState("SQL");
    const [leadIndustry, setLeadIndustry] = useState("Airlines");
    const [leadRole, setLeadRole] = useState("IT Manager");
    const [leadSize, setLeadSize] = useState("1-10");
    const [score, setScore] = useState([]);
    const [tags, setTags] = useState([]);
    const [stages, setStages] = useState([]);
    useEffect(() => {
        if (
          window?.global_script?.scores &&
          window?.global_script?.scores?.length
        ) {
          setScore(window?.global_script?.scores);
        }
        if (window?.global_script?.tags && window?.global_script?.tags?.length) {
          setTags(window?.global_script?.tags);
        }
        if (
          window?.global_script?.stages &&
          window?.global_script?.stages?.length
        ) {
          setStages(window?.global_script?.stages);
        }
      }, []);

      const saveScore = async (score) => {
        var infoData = { score: score };
        await postJson(`leads/${lead.id}/scores`, JSON.stringify(infoData));
      };
    
      const saveTags = async (tags) => {
        var infoData = { lead_id: lead.id, tags: [tags] };
        await postJson(`leads/add-tags`, JSON.stringify(infoData));
      };
    
      const saveStages = async (tags) => {
        var infoData = { lead_id: lead.id, stage: tags };
        await postJson(`user/change-lead-stage`, JSON.stringify(infoData));
      };
    const onSave = async (e) => {
        e.preventDefault();

        var saveCallBtn = document.getElementById("save-call-summary-btn");
        saveCallBtn.disabled = true;
        saveCallBtn.value = "Please wait...";

        //Save score
        var scoreData = new FormData();
        scoreData.append("lead_id", lead.id);
        // scoreData.append("score", leadScore);
        // await post(`leads/${lead.id}/scores`, scoreData);

        //Save industry
        var infoData = { "role": leadRole, "industry": leadIndustry, "employees": leadSize};
        await post(`leads/${lead.id}/info`, JSON.stringify(infoData));

        saveCallBtn.disabled = false;
        saveCallBtn.value = "Save";
    }

    return (
        <>
            <div className="c-s-form">
                <form onSubmit={onSave} id="lead-details-form" action="">
{/*         
          <div className="item">
            <label htmlFor="">Score</label>
            <select
              value={lead?.score}
              onChange={(e) => {
                saveScore(e.target.value);
              }}
              id="lead_interest"
            >
              <option key={"score"} value={""}>
                Score
              </option>
              {score?.map((list, idx) => {
                return (
                  <option key={idx} value={list}>
                    {list}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className="item">
            <label htmlFor="">Tags</label>
            <select
              value={lead?.tags ? JSON.parse(lead?.tags)?.[0] : ""}
              onChange={(e) => {
                saveTags(e.target.value);
              }}
              id="lead_interest"
            >
              <option key={"score"} value={""}>
                Tags
              </option>
              {tags?.map((list, idx) => {
                return (
                  <option key={idx} value={list}>
                    {list}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="item">
            <label htmlFor="">Stages</label>
            <select
              value={lead?.stage}
              onChange={(e) => {
                saveStages(e.target.value);
              }}
              id="lead_interest"
            >
              <option key={"score"} value={""}>
                Stages
              </option>
              {stages?.map((list, idx) => {
                return (
                  <option key={idx} value={list}>
                    {list}
                  </option>
                );
              })}
            </select>
          </div>

                    {/* <div className="item">
                        <label htmlFor="">Lead score:</label>
                        <select value={leadScore} onChange={(e)=>{ setLeadScore(e.target.value) }} id="lead_score">
                            <option>MQL</option>
                            <option>SQL</option>
                            <option>SAL</option>
                            <option>CUSTOMER</option>
                            <option>PARTNER</option>
                            <option>WRONG FIT</option>
                        </select>
                    </div> */}

                    <div className="item">
                        <label htmlFor="">Industry:</label>
                        <select value={leadIndustry} onChange={(e)=>{ setLeadIndustry(e.target.value) }} id="lead_industry">
                            <option>Accounting</option>
                            <option>Airlines</option>
                            <option>Aviation</option>
                            <option>Alternative Dispute Resolution</option>
                            <option>Animation</option>
                            <option>Apparel/Fashion</option>
                            <option>Architecture/Planning</option>
                            <option>Arts/Crafts</option>
                            <option>Aviation/Aerospace</option>
                            <option>Banking/Mortgage</option>
                            <option>Biotechnology/Greentech</option>
                            <option>Broadcast Media</option>
                            <option>Building Materials</option>
                            <option>Business Equipment</option>
                            <option>Business Supplies</option>
                            <option>Capital Markets</option>
                            <option>Hedge Fun</option>
                            <option>Private Equity</option>
                            <option>Chemicals</option>
                            <option>Civic/Social Organization</option>
                            <option>Civil Engineering</option>
                            <option>Commercial Real Estate</option>
                            <option>Computer Games</option>
                            <option>Computer Hardware</option>
                            <option>Computer Networkin</option>
                            <option>Computer Engineerin</option>
                            <option>Software Engineering</option>
                            <option>Network Security</option>
                            <option>Construction</option>
                            <option>Consumer Electronics</option>
                            <option>Consumer Goods</option>
                            <option>Consumer Services</option>
                            <option>Cosmetics</option>
                            <option>Dairy</option>
                            <option>Defense/Space</option>
                            <option>Design</option>
                            <option>E-Learning</option>
                            <option>Education Management</option>
                            <option>Electrical Manufacturing</option>
                            <option>Electronic Manufacturing</option>
                            <option>Entertainment Production</option>
                            <option>Movie Production</option>
                            <option>Environmental Services</option>
                            <option>Events Services</option>
                            <option>Executive Office</option>
                            <option>Facilities Services</option>
                            <option>Financial Services</option>
                            <option>Food Production</option>
                            <option>Food/Beverage</option>
                            <option>Fundraising</option>
                            <option>Furniture</option>
                            <option>Gambling/Casinos</option>
                            <option>Glass/Ceramics/Concrete</option>
                            <option>Government Administration</option>
                            <option>Government Relations</option>
                            <option>Graphic Design/Web Design</option>
                            <option>Health/Fitness</option>
                            <option>Higher Education</option>
                            <option>Hospital/Health Care</option>
                            <option>Hospitality</option>
                            <option>Human Resources/HR</option>
                            <option>Import/Export</option>
                            <option>Individual Services</option>
                            <option>Family Services</option>
                            <option>Industrial Automation</option>
                            <option>Information Services</option>
                            <option>Information Technology</option>
                            <option>Insurance</option>
                            <option>International Affairs</option>
                            <option>Trade/Development</option>
                            <option>Internet</option>
                            <option>Investment Banking</option>
                            <option>Investment Venture</option>
                            <option>Investment Management</option>
                            <option>Hedge Fund</option>
                            <option>Private Equity</option>
                            <option>Judiciary</option>
                            <option>Law Enforcement</option>
                            <option>Law Practice/Law Firms</option>
                            <option>Legal Services</option>
                            <option>Legislative Office</option>
                            <option>Leisure/Travel</option>
                            <option>Library</option>
                            <option>Logistics/Procurement</option>
                            <option>Luxury Goods/Jewelry</option>
                            <option>Machinery</option>
                            <option>Management Consulting</option>
                            <option>Maritime</option>
                            <option>Market Research</option>
                            <option>Marketing/Sales</option>
                            <option>Advertising</option>
                            <option>Mechanical Engineering</option>
                            <option>Industrial Engineering</option>
                            <option>Media Production</option>
                            <option>Medical Equipment</option>
                            <option>Medical Practice</option>
                            <option>Mental Health Care</option>
                            <option>Military Industr</option>
                            <option>Mining/Metals</option>
                            <option>Motion Pictures/Film</option>
                            <option>Museums/Institutions</option>
                            <option>Music</option>
                            <option>Nanotechnology</option>
                            <option>Newspapers/Journalism</option>
                            <option>Non-Profit/Volunteering</option>
                            <option>Solar/Greentech</option>
                            <option>Oil/Energy</option>
                            <option>Online Publishing</option>
                            <option>Other Industry</option>
                            <option>Outsourcing/Offshoring</option>
                            <option>Package Delivery</option>
                            <option>Freight Delivery</option>
                            <option>Packaging/Containers</option>
                            <option>Paper/Forest Products</option>
                            <option>Performing Arts</option>
                            <option>Pharmaceuticals</option>
                            <option>Philanthropy</option>
                            <option>Photography</option>
                            <option>Plastics</option>
                            <option>Political Organization</option>
                            <option>Primary Education</option>
                            <option>Secondary Education</option>
                            <option>Printing</option>
                            <option>Professional Training</option>
                            <option>Program Development</option>
                            <option>Public Relations/PR</option>
                            <option>Public Safety</option>
                            <option>Publishing Industry</option>
                            <option>Railroad Manufacture</option>
                            <option>Ranching</option>
                            <option>Real Estate/Mortgage</option>
                            <option>Recreational Services</option>
                            <option>Religious Institutions</option>
                            <option>Renewables/Environment</option>
                            <option>Research Industry</option>
                            <option>Restaurants</option>
                            <option>Retail Industry</option>
                            <option>Security/Investigations</option>
                            <option>Semiconductors</option>
                            <option>Shipbuilding</option>
                            <option>Sporting Goods</option>
                            <option>Sports</option>
                            <option>Staffing/Recruiting</option>
                            <option>Supermarkets</option>
                            <option>Telecommunication</option>
                            <option>Think Tanks</option>
                            <option>Tobacco</option>
                            <option>Translation/Localization</option>
                            <option>Transportation</option>
                            <option>Utilities</option>
                            <option>Venture Capital/VC</option>
                            <option>Veterinary</option>
                            <option>Warehousing</option>
                            <option>Wholesale</option>
                            <option>Wine/Spirits</option>
                            <option>Wireless</option>
                            <option>Writing/Editing</option>
                        </select>
                    </div>

                    <div className="item">
                        <label htmlFor="">Role:</label>
                        <select value={leadRole} onChange={(e)=>{ setLeadRole(e.target.value) }} id="lead_role">
                            <option>Sale Manager</option>
                            <option>Marketing / PR Manager</option>
                            <option>Customer Services Manager</option>
                            <option>CXO / General Manager</option>
                            <option>IT Manager</option>
                            <option>Operation Manager</option>
                            <option>Developer</option>
                            <option>Software Engineer</option>
                            <option>Analyst</option>
                            <option>Student</option>
                            <option>Personal Interest</option>
                            <option>Other</option>
                        </select>
                    </div>

                    <div className="item">
                        <label htmlFor="">Organization size:</label>
                        <select value={leadSize} onChange={(e)=>{ setLeadSize(e.target.value) }} id="lead_company_size">
                            <option>1-10</option>
                            <option>11-50</option>
                            <option>51-250</option>
                            <option>251-1K</option>
                            <option>1k-5k</option>
                            <option>5K-10K</option>
                            <option>10K-50K</option>
                            <option>50K-100K</option>
                            <option>100K+</option>
                        </select>
                    </div>

                    <div className="actions">
                        <input id="save-call-summary-btn" className="btn-main" type="submit" value="Save" />
                    </div>
                    <div className="actions">
                        <input id="save-call-summary-btn" className="btn-main" type="submit" value="Follow UP" />
                    </div>
                </form>
            </div>
        </>
    );
}