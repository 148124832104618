import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import uuid from "react-uuid";
import { PhoneIcon } from "@heroicons/react/24/outline";
import ChatMessage from "../components/ChatMessage";
import FullscreenModal from "../components/FullscreenModal";
import { ReactComponent as Info } from "../images/info.svg";
import { ReactComponent as Close } from "../images/close.svg";
import { ReactComponent as Assign } from "../images/assign.svg";
import priority from "../images/priority.png";
import send from "../images/send.svg";
import note from "../images/edit-note.svg";
import backIcon from "../images/backicon.png";
import closeConversationIcon from "../images/close-conversation.svg";
import { getScritId } from "../helpers/globals";
import {
  deleteJson,
  errorAlert,
  post,
  postJson,
  putJson,
} from "../helpers/network";
import { Link } from "react-router-dom";

const prioritys = [
  {
    key: 1,
    text: "Low",
    value: 1,
  },
  {
    key: 3,
    text: "Medium",
    value: 3,
  },
  {
    key: 4,
    text: "High",
    value: 4,
  },
];

const compareNotes = (a, b) => {
  const d1 = new Date(a.created_at);
  const d2 = new Date(b.created_at);
  return d1 - d2;
};

export default function Chat({
  conversation,
  onClosed,
  setShowSwipupModal,
  handleReload,
  handleChangeAssign,
  assignList,
}) {
  const [chatText, setChatText] = useState();
  const [noteActive, setNoteActive] = useState(false);
  const [text, setText] = useState("ok");
  const [openModal, setOpenModal] = useState(false);
  const [messages, setMessages] = useState(
    conversation?.messages
      ?.concat(conversation.notes)
      ?.sort(compareNotes)
      ?.reverse()
  );
  const [selectedSource, setSelectedSource] = useState(conversation.source);
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (conversation?.friendly_name) {
      let vla = conversation?.friendly_name.split("+"),
        num = "";
      if (vla?.length > 0) {
        if (vla.length > 1) {
          num = "+" + vla[1];
        } else {
          num = "+" + vla[0];
        }
      }
      setNumber(num);
    } else {
      setNumber("");
    }
  }, [conversation]);

  useEffect(() => {
    document.querySelector("#fullscreen-modal").style.overflow = "inherit";
    document.querySelector("#fullscreen-modal").style.marginTop = "0";
    document.querySelector("#message-details-holder").scrollTop =
      document.querySelector("#message-details-holder").scrollHeight;
    return function cleanup() {
      try {
        document.querySelector("#fullscreen-modal").style.overflow = "scroll";
      } catch (e) {}
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.querySelector("#message-details-holder").scrollTop =
        document.querySelector("#message-details-holder").scrollHeight;
    }, 500);
  }, [messages, text]);

  const sendMessage = async () => {
    var _messages = messages;
    var mid = uuid();
    _messages.unshift({
      id: mid,
      message_type: "Message",
      body: chatText,
      direction: "outgoing",
      status: "pending",
      created_at: new Date().toString(),
    });

    setMessages(_messages);
    setChatText("");
    setText(uuid());

    await postJson(
      "conversation/agent-push-message",
      JSON.stringify({
        agent_code: window.user.code,
        sid: conversation.sid,
        body: chatText,
      })
    );

    for (var i = 0; i < _messages.length; i++) {
      if (_messages[i].id == mid) {
        _messages[i].status = "delivered";
        break;
      }
    }

    setMessages(_messages);
    setText(uuid());
  };

  const closeConversation = async (e) => {
    e.target.disabled = true;

    var result = await putJson(
      `conversation/${conversation.id}/update`,
      JSON.stringify({
        state: conversation?.state == "closed" ? 1 : 3,
      })
    );
    if (result?.status === 200) {
      handleReload();
    }
    onClosed(conversation?.state == "closed" ? false : true);
  };

  const onSnooze = async (e) => {
    e.target.style.opacity = "0.5";

    var result = await putJson(
      `conversation/${conversation.id}/update`,
      JSON.stringify({
        state: 2,
      })
    );
    setOpenModal(false);
    // onClosed(true);
  };

  const deleteConversation = async (e) => {
    e.target.style.opacity = "0.5";

    var scriptId = await getScritId();
    var payload = {
      ids: [conversation.id],
      script_id: scriptId,
    };
    var result = await deleteJson(
      `conversation/delete`,
      JSON.stringify(payload)
    );
    setOpenModal(false);
    onClosed(true);
  };

  const addNote = async () => {
    var _messages = messages;
    var mid = uuid();
    _messages.unshift({
      id: mid,
      note: chatText,
      direction: "note",
      from_user_id: 700,
      from_user_name: "Muhammad mamdouh",
      created_at: new Date().toString(),
      mentions: [],
      related_to_type: "",
    });

    setMessages(_messages);
    setChatText("");
    setText(mid);
    var payload = new FormData();
    payload.append("note", chatText);
    var result = await post(`conversation/${conversation.id}/note`, payload);
    result = await result.json();
    if (result?.data) {
      handleReload();
    }
  };

  const addNotes = () => {
    setNoteActive(!noteActive);
  };

  const handleChangePriority = async (e, conversation) => {
    const data = {
      priority: e?.target?.value,
    };
    var result = await putJson(
      `conversation/${conversation?.id}/update`,
      JSON.stringify(data)
    );
    result = await result.json();
    if (!result?.errors?.length) {
      handleReload();
    } else {
      errorAlert(result?.errors[0] || "Something went to wrong!");
    }
  };

  const handleModalUI = () => {
    return (
      <>
        <Close
          className="close-icon"
          onClick={() => setOpenModal(!openModal)}
        />
        <ul className="openModal">
          <li onClick={onSnooze}>
            <a title="Snooze">
              <svg
                id="snooze-btn"
                data-tooltip-content="Snooze"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-white cursor-pointer"
                title="Snooze"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M10.5 8.25h3l-3 4.5h3"
                />
              </svg>
              Snooze
            </a>
          </li>
          <li onClick={deleteConversation}>
            <a title="Delete">
              <svg
                id="delete-btn"
                data-tooltip-content="Delete"
                onClick={deleteConversation}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-white cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
              Delete
            </a>
          </li>
          <li onClick={closeConversation}>
            <a title="Close Conversation">
              <img
                src={closeConversationIcon}
                className="mr-3"
                style={{ height: "30px", width: "28px" }}
                alt="close"
              />
              {conversation?.state == "closed"
                ? "Re-Open conversation"
                : "Close Conversation"}
            </a>
          </li>

          <li>
            <a title="Assign">
              {/* <Assign className="image-size" /> */}
              <img
                src={priority}
                className="image-size mr-3"
                alt="priority"
                style={{ height: "30px", width: "24px" }}
              />{" "}
              <select
                className="font-bold text-sm bg-transparent focus:outline-none"
                onChange={(e) => handleChangePriority(e, conversation)}
                defaultValue={
                  conversation?.priority
                    ? conversation?.priority?.toString()
                    : ""
                }
              >
                <option value="">Priority</option>

                {prioritys &&
                  prioritys?.length > 0 &&
                  prioritys?.map((val, ind) => {
                    return (
                      <option key={ind} value={val?.value?.toString()}>
                        {val?.text}
                      </option>
                    );
                  })}
              </select>
            </a>
          </li>
          <li>
            <a title="Mark as priority">
              <Assign
                className="image-size"
                style={{ height: "27px", width: "24px" }}
              />
              <select
                className="font-bold text-sm bg-transparent focus:outline-none"
                onChange={(e) => handleChangeAssign(e, conversation)}
                defaultValue={
                  conversation?.owner_id
                    ? conversation?.owner_id?.toString()
                    : ""
                }
              >
                <option value="">Assign</option>
                {assignList &&
                  assignList?.length > 0 &&
                  assignList?.map((val, ind) => {
                    return (
                      <option key={ind} value={val?.value?.toString()}>
                        {val?.text}
                      </option>
                    );
                  })}
              </select>
            </a>
          </li>
        </ul>
      </>
    );
  };

  return (
    <section className="call-summary mh h-full flex flex-col !pb-16">
      <div className="m-s-top mb-0">
        <span className="text-white">{selectedSource}</span>

        <span
          onClick={() => {
            setShowSwipupModal(true);
          }}
          id="cc_message_btn"
          title="New Message"
        >
          <svg
            id="new-message-btn"
            data-tooltip-content="New message"
            title="Message"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 -mt-2 cursor-pointer text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
            />
          </svg>
        </span>
      </div>

      <div className="m-s-top mt-0">
        <div>
          <a
            onClick={() => {
              document.querySelector("#fullscreen-modal").style.overflow =
                "scroll";
              onClosed();
            }}
            id="goto-messages"
            className="text-white hover:!text-[#12c197] close-settings"
            href="#"
          >
            <img src={backIcon} alt={'Back'} style={{
              height: "25px",
              width: "25px",
}} />
          </a>
        </div>

        <span className="text-white d-flex">
          {conversation.friendly_name}{" "}
        </span>

        <div className="flex gap-3">
        {number && (
            <Link
              id="goto-home"
              to="/dialer"
              state={{
                number,
              }}
            >
              <PhoneIcon
                width="18"
                height="18"
                viewBox="0 0 18 20"
                className="icon-overflow ml-2"
                color="#fff"
              />
            </Link>
          )}
          <a title="Info" onClick={() => setOpenModal(!openModal)}>
            <Info className="image-size" />
          </a>
          {/* <a title="Snooze">
            <svg
              id="snooze-btn"
              data-tooltip-content="Snooze"
              onClick={onSnooze}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white cursor-pointer"
              title="Snooze"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M10.5 8.25h3l-3 4.5h3"
              />
            </svg>
          </a>
          <a title="Delete">
            <svg
              id="delete-btn"
              data-tooltip-content="Delete"
              onClick={deleteConversation}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </a> */}
        </div>
      </div>

      <div
        id="message-details-holder"
        className="leads-list flex-1 overflow-y-auto chat-messages"
      >
        {messages &&
          (messages.length > 0
            ? messages
                .slice(0)
                .reverse()
                .map((message, index) => (
                  <ChatMessage
                    key={index}
                    message={message}
                    conversation={conversation}
                  />
                ))
            : "No messages")}
      </div>
      <div
        className="relative bottom-0 right-0 left-0 px-1 mt-auto send-sms-blog d-flex align-items-center"
        style={{ backgroundColor: "#171b26" }}
      >
        <textarea
          className={`w-full ${!noteActive ? "" : "textarea-yellow"}`}
          rows="1"
          placeholder="Message"
          value={chatText}
          id="add-message-input"
          onChange={(e) => {
            setChatText(e.target.value);
          }}
        ></textarea>
        <span onClick={addNotes}>
          <img src={note} alt="send" />
        </span>
        <span
          className="send-icon"
          onClick={() => {
            !noteActive ? sendMessage() : addNote();
          }}
        >
          <img src={send} alt="send" />
        </span>
      </div>

      <Tooltip anchorId="delete-btn" />
      <Tooltip anchorId="snooze-btn" />
      <Tooltip anchorId="new-message-btn" />
      {openModal && (
        <FullscreenModal
          showTopNav={false}
          customeClass="full-screen"
          component={handleModalUI()}
        />
      )}
    </section>
  );
}
