import { useEffect, useState } from "react";

export function Permissions() {

    const [status, setStatus] = useState("Limephone requires audio permissions to work");

    const allow = () => {
        navigator.getUserMedia(
            {
                audio: true
            },
            function (localMediaStream) {
                setStatus("All good! Reload the page to start using the extension now.");
            },
            function (err) {
                setStatus("Oops! We could not get the audio permission.");
            }
        );
    }

    return (
        <>
            <section className="call-summary pb-24">
                <div className="m-s-top">
                    <h1>Allow audio permission</h1>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.70703 0.292969L0.292969 1.70703L6.58594 8L0.292969 14.293L1.70703 15.707L8 9.41406L14.293 15.707L15.707 14.293L9.41406 8L15.707 1.70703L14.293 0.292969L8 6.58594L1.70703 0.292969Z"
                            fill="#A4ABC4" />
                    </svg>
                </div>
                <div className="c-s-user mb-12 pt-12">
                    <div className="flex justify-center my-4">
                        <h3 id="status">{status}</h3>

                    </div>
                    <div className="actions">
                        <button id="allow-audio" className="btn-main mt-2">Allow audio permissions</button>
                    </div>
                </div>
            </section>
        </>
    );

}
